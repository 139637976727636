.form-group.required .form-control-label::before {
  content: "*";
  color: #c00; }

.login-page {
  margin-top: 1em; }
  .login-page .login-form-nav {
    margin-bottom: 1.25em; }
    .login-page .login-form-nav .tab-pane {
      margin-top: 1em; }
  .login-page .login {
    margin-bottom: 0.938em; }
    .login-page .login .error-message-form {
      padding: 0.625em;
      background-color: #c00;
      color: #fff;
      border-radius: 0.188em; }

.registration-page .privacy-policy {
  margin-top: 1rem; }

.login-banner {
  background-image: url("../images/account.jpg");
  background-position-y: 40%; }

.equal-height .card {
  width: 100%; }

.track-order-header {
  font-size: 1.75rem; }

.request-password-title {
  font-size: 1.5rem; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.form-group.required .form-control-label::before {
  content: "*";
  color: #d00a2d; }

.form-group {
  position: relative; }
  .form-group > span:not(.info-icon) {
    font-size: 80%; }

.custom-control .custom-control-input:focus ~ .custom-control-label:before {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none; }

.custom-control .custom-control-input:focus-visible ~ .custom-control-label:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: -webkit-focus-ring-color auto 1px; }

.floating-label {
  position: relative; }
  .floating-label label {
    position: absolute;
    margin: 0;
    opacity: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
  .floating-label input {
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
    .floating-label input:-webkit-autofill ~ label, .floating-label input:not(:placeholder-shown) ~ label {
      -webkit-transform: translateY(-0.625rem);
              transform: translateY(-0.625rem);
      opacity: 0.7; }
  .floating-label select {
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
  .floating-label.value-set select + label {
    -webkit-transform: translateY(-0.625rem);
            transform: translateY(-0.625rem);
    opacity: 0.7; }

form.floating .btn-form-inline {
  height: calc(100% - 0.625rem); }

.custom-checkbox {
  margin-bottom: 0.25rem; }
  .custom-checkbox .custom-control-label,
  .custom-checkbox label {
    cursor: pointer; }
  .custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
    cursor: default; }
  .custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label {
    color: var(--skin-label-color); }

.custom-radio .custom-control-label,
.custom-radio label {
  cursor: pointer; }

.custom-radio .custom-control-label::before {
  background: none; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: none; }

.custom-radio .custom-control-input:disabled ~ .custom-control-label {
  cursor: default; }

.custom-switch .custom-control-label,
.custom-switch label {
  cursor: pointer; }

.custom-switch .custom-control-input:disabled ~ .custom-control-label {
  cursor: default; }

.custom-password-field.invalid-field .show-hide-password .svg-icon {
  color: var(--color-red300); }

.custom-password-field .form-control.is-invalid {
  background-image: none; }

.password-criteria-wrapper {
  margin-top: 0.5rem; }

.password-description {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: right;
  color: var(--color-grey6);
  margin-bottom: 1rem; }

.password-criteria strong {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--color-blue-dark);
  margin-bottom: 0.5rem; }

.password-criteria ul {
  list-style-type: none;
  padding: 0; }
  .password-criteria ul li {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--color-blue-dark); }

.password-criteria .svg-icon {
  display: none;
  margin-right: 0.25rem; }

.password-criteria .criteria-fail-icon {
  color: var(--color-blue-dark); }

.password-criteria .criteria-pass-icon {
  color: var(--color-green); }

.password-criteria .invalid .criteria-fail-icon {
  display: inline-block; }

.password-criteria .valid .criteria-pass-icon {
  display: inline-block; }

.show-hide-password {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0.625rem; }
  .show-hide-password .eye-empty-icon {
    display: none; }
  .show-hide-password.show .eye-empty-icon {
    display: inline-block; }
  .show-hide-password.show .eye-off-icon {
    display: none; }

.password-strength {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 0.3125rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem; }
  .password-strength > span {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: inline-block;
    margin-right: 0.25rem;
    background: var(--color-grey2);
    border-radius: 0.25rem;
    height: 0.25rem; }
  .password-strength.red span:nth-child(-n + 1) {
    background-color: var(--password-strength-color1); }
  .password-strength.orange span:nth-child(-n + 2) {
    background-color: var(--password-strength-color2); }
  .password-strength.green span:nth-child(-n + 3) {
    background-color: var(--password-strength-color3); }
  .password-strength.darkGreen span:nth-child(-n + 4) {
    background-color: var(--password-strength-color4); }

[data-action="Account-ForgetPasswordModel"],
[data-action="Account-DoSetNewPassword"],
[data-action="Account-PasswordReset"] {
  background-color: var(--color-grey1); }

#site-main .back-button-link {
  margin-top: 1.5rem; }

.card-box-wrapper {
  background-color: var(--color-white);
  padding: 1.5rem 1rem;
  border-radius: 0.25rem;
  margin-bottom: 2.875rem;
  margin-top: 1.5rem; }
  .card-box-wrapper.border-fix {
    -webkit-box-shadow: 0 0 0.3125rem 0.3125rem var(--color-grey1);
            box-shadow: 0 0 0.3125rem 0.3125rem var(--color-grey1); }
  .card-box-wrapper .card-box-title {
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--color-blue-dark);
    margin-bottom: 0.5rem; }
  .card-box-wrapper .card-box-desc {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--color-blue-dark);
    margin-bottom: 1.5rem; }
  .card-box-wrapper .card-box-body,
  .card-box-wrapper .button-margin {
    margin-bottom: 1.5rem; }
  .card-box-wrapper .form-group:last-child {
    margin-bottom: 1.5rem; }

@media (min-width: 769px) {
  .card-box-wrapper {
    width: 27.5rem;
    margin: auto;
    margin-bottom: 9.625rem;
    margin-top: 2.5rem;
    padding: 2.5rem 1.5rem; }
    .card-box-wrapper .card-box-title {
      font-size: 2rem;
      line-height: 2.5rem;
      margin-bottom: 1rem; }
    .card-box-wrapper .card-box-desc {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 2rem; }
    .card-box-wrapper .card-box-body,
    .card-box-wrapper .button-margin {
      margin-bottom: 2rem; }
    .card-box-wrapper .form-group:last-child {
      margin-bottom: 2rem; } }

[data-action="Account-ForgetPasswordModel"] .back-button,
[data-action="Account-PasswordReset"] .back-button {
  margin: 1.5rem 0;
  color: var(--skin-link-color);
  font-size: 0.875rem;
  line-height: 1rem;
  text-decoration: none; }

.forgot-password-wrapper .send-email-btn {
  margin-bottom: 2.5rem; }

.forgot-password-wrapper.email-sent .send-email-btn {
  display: none; }

.contact-us-wrapper h2 {
  color: var(--color-blue-dark);
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem; }

.contact-us-wrapper p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1rem; }

.contact-us-wrapper a {
  display: inline-block;
  color: var(--skin-link-color);
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: capitalize;
  margin-bottom: 1.5rem;
  text-decoration: none; }

@media (min-width: 769px) {
  [data-action="Account-ForgetPasswordModel"] .back-button,
  [data-action="Account-PasswordReset"] .back-button {
    margin-bottom: 2.5rem;
    font-size: 1rem;
    line-height: 1.125rem; }
  .forgot-password-wrapper .send-email-btn {
    margin-bottom: 2rem; }
  .contact-us-wrapper h2 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.625rem; }
  .contact-us-wrapper p {
    margin-bottom: 0.5rem; }
  .contact-us-wrapper a {
    margin-bottom: 1.125rem; } }

.set-new-password-success .icon-check-circle {
  width: 3rem;
  height: 3rem;
  color: var(--color-green);
  margin-bottom: 0.5rem; }

#register .has-tooltip {
  gap: 0.625rem;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  #register .has-tooltip .year-field {
    width: 100%; }
    @media (min-width: 769px) {
      #register .has-tooltip .year-field {
        width: 84%; } }
  #register .has-tooltip .tooltip-show {
    margin-top: 1.25rem;
    margin-left: auto; }

#register .bday-info.party-icon {
  background-image: url(../images/partyhat-bear-icon.png);
  background-repeat: no-repeat;
  background-position: 0 4px;
  background-size: 32px auto;
  padding-left: 40px; }

#register .bday-info label {
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 0;
  text-align: left; }
  @media (min-width: 769px) {
    #register .bday-info label {
      width: calc(75% - 40px); } }

#register .bday-info span {
  font-size: 0.875rem;
  display: block;
  color: var(--color-green); }
  @media (min-width: 769px) {
    #register .bday-info span {
      margin-right: 17%; } }
  #register .bday-info span.bday-info-text {
    color: var(--color-blue);
    padding: 0.3125rem 0; }

#register #bday-input .floating-label.value-set select + label {
  left: 1.375rem; }
